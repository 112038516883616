<template>
  <v-container class="d-flex justify-center align-center" style="height: 100vh;">
    <v-card height="470" width="600" :loading="loading">
      <!-- Logo Header -->
      <div style="height: 175px" class="d-flex justify-center pa-4 pt-8">
        <logo-image />
      </div>
      <v-scroll-x-reverse-transition mode="out-in">
        <!-- MFA -->
        <div key="multiFactor" v-if="resolver">
          <multi-factor-auth :resolver="resolver" @success="loginWithToken" @back="cancelMFA" />
        </div>
        <!-- Login Form -->
        <div v-else key="login">
          <v-card-text class="d-flex justify-center align-center pt-10">
            <v-form
              style="width: 300px"
              class="d-flex flex-column justify-center align-center"
              @submit.prevent="login"
            >
              <v-text-field
                label="Email"
                outlined
                style="width: 100%"
                v-model="email"
                type="email"
              />
              <v-text-field
                label="Password"
                outlined
                style="width: 100%"
                v-model="password"
                type="password"
              />
              <v-btn type="submit" color="primary" :disabled="loading || !completed">Login</v-btn>
            </v-form>
          </v-card-text>
        </div>
      </v-scroll-x-reverse-transition>
    </v-card>
  </v-container>
</template>

<script>
// Packages, constants, utils
import to from 'await-to-js';
import { getAuth, signInWithEmailAndPassword, getMultiFactorResolver } from 'firebase/auth';
import { onLogin } from '../vue-apollo';
import { MEMBER } from '../constants/roles';
import { ERRORS } from '../constants/messages';

// Components
import LogoImage from '../components/LogoImage.vue';

// GQL
import LOGIN from '../graphql/Mutation/Login.gql';
import GET_PRIMARY_ROLE from '../graphql/Query/GetPrimaryRole.gql';

export default {
  name: 'Login',
  data() {
    return {
      disabled: false,
      email: null,
      password: null,
      loading: false,
      resolver: null,
    };
  },
  components: { LogoImage, MultiFactorAuth: () => import('../components/MultiFactorAuth.vue') },
  methods: {
    cancelMFA() {
      this.resolver = null;
    },
    async checkIsMember(token) {
      // use token to check member
      const [getPrimaryRoleErrors, getPrimaryRoleQuery] = await to(
        this.$apollo.query({
          query: GET_PRIMARY_ROLE,
          variables: {
            token,
          },
        })
      );
      // if member throw error
      if (
        getPrimaryRoleErrors ||
        getPrimaryRoleQuery.errors ||
        getPrimaryRoleQuery.data.getPrimaryRole === MEMBER
      ) {
        throw new Error('User lacks permission to access resource.');
      }
    },
    async getTenantId() {
      const { tenantUrl = null } = this.$route?.params || {};
      if (!tenantUrl) {
        return null;
      }
      const [errors, query] = await to(
        this.$apollo.query({
          query: await import('../graphql/Query/GetTenantId.gql'),
          variables: {
            tenantUrl,
          },
        })
      );
      if (errors) return null;
      return query.data.getTenantId;
    },
    handleLoginError(error) {
      this.loading = false;
      this.disabled = false;
      if (!this.isMultiFactorRequired(error)) {
        this.errorMessage = ERRORS.login;
      } else {
        this.loginToFireStore(this.email, this.password);
      }
    },
    isMultiFactorRequired(error) {
      // Bandaid fix waiting on @andy backend fix
      const multiFactorText = new RegExp('Proof of ownership of a second factor is required');
      return multiFactorText.test({ ...error }?.graphQLErrors[0]?.message);

      // Ultimate solution, waiting on backend fix
      // return (
      //   { ...error }?.graphQLErrors &&
      //   { ...error }.graphQLErrors[0]?.extensions?.exception?.code === 'MultiFactorError'
      // );
    },
    async login() {
      this.loading = true;
      const { email, password, tenantUrl } = this;
      try {
        const result = await this.$apollo.mutate({
          mutation: LOGIN,
          variables: {
            email,
            password,
            tenantUrl,
          },
        });

        const { login: token } = result.data;

        await this.checkIsMember(token);
        await onLogin(token);
        await this.$store.dispatch('getAndSaveUser', tenantUrl);
        this.$router.push({ name: 'Main' });
      } catch (e) {
        this.handleLoginError(e);
        this.$store.dispatch('setNotification', {
          color: 'error',
          timeout: -1,
          text: `Error logging in. Please try again.`,
        });
      }
      this.loading = false;
    },
    async loginToFireStore(email, password) {
      const auth = getAuth();
      // TODO: Query this from back-end once query is created
      auth.tenantId = await this.getTenantId();
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          // Signed in
          // ...
        })
        .catch((error) => {
          if (error.code === 'auth/multi-factor-auth-required') {
            this.resolver = getMultiFactorResolver(auth, error);
          }
        });
    },
    async loginWithToken(token) {
      this.disabled = true;
      this.errorMessage = '';
      this.loading = true;
      const { tenantUrl } = this.$route.params;
      if (await this.checkIsMember(token)) {
        return;
      }

      await onLogin(token);

      await this.$store.dispatch('getAndSaveUser', tenantUrl);
      this.$router.push({ name: 'Main' });
    },
  },
  computed: {
    completed() {
      return !!this.email || !!this.password;
    },
    tenantUrl() {
      return this.$route.params?.tenantUrl;
    },
  },
};
</script>
